.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px; /* Ajusta esto según sea necesario */

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 4px;

    .header-title {
      font-size: 1em;
      margin: 0;
      margin-right: 8px;
    }

    .header-content {

      .location-button {
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        >svg {
          fill: white;
          width: 20px;
          height: 20px;
        }

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .coordinates {
      font-size: 0.8em;
      text-align: center;
      margin: 5px 0;

      @media (min-width: 600px) {
        text-align: right;
        margin: 0;
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: 5px;

    @media (min-width: 600px) {
      margin-top: 0;
    }
  }
}
