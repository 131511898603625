.date-slider {
  display: flex;
  width: 100%;
  white-space: nowrap;

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    position: sticky;
    z-index: 10;
    top: 0;
    >button {
      background-color: #007bff;
      border: none;
      border-radius: 4px;
      color: white;
      padding: 0;
      height: 100%;
      svg {
        width: 20px;
        height: auto;
        fill: white;
      }
    }
  }

  .days-row {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory; // Habilita el scroll snap
  }

  .day-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    &:nth-child(odd) {
      background-color: #e6f7ff; // Fondo azul muy claro
    }
  }

  .day-label {
    font-weight: bold;
    padding: 2px 0;
    font-size: 12px;
  }

  .hours-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  .hour-cell {
    flex: 1;
    padding: 4px;
    margin: 2px;
    border: 1px solid #ddd;
    cursor: pointer;
    text-align: center;
    min-width: 20px;
    font-size: 10px;
    color: white;
    border-radius: 4px;
    scroll-snap-align: start; // Alinea el elemento con el inicio del contenedor de desplazamiento
  }

  .hour-cell.selected {
    outline: 2px solid #007bff;
  }
}
