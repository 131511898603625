.windy-maps-container {
  display: flex;
  height: 600px;
  width: 100%;
  margin-top: 8px;

  .windy-map {
    width: 100%;
    height: 100%;
    border: none;
  }
}
