.aemet-image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 50%;
}

.aemet-image {
  max-width: 100%;
  height: auto;
}
