.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Just below the .map-popup */
}

.map-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1000;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-popup .close-button {
  align-self: flex-end;
  background: gray;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  position: fixed;
  top: 2%;
  right: 2%;
  z-index: 1000000;
  font-weight: bold;
}

.map-popup .save-button {
  align-self: flex-end;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 1000000;
  font-weight: bold;
}

.map-container {
  position: relative;
}

.map-container div {
  position: absolute;
  pointer-events: none;
}
